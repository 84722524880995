import React, { Component, Fragment } from "react";
import logo from "../../../Assets/img/logoImage.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Translate from "../../../../global/Translate";
import MyDesktop from "../../MyComponents/MyDesktop";
import MyMobile from "../../MyComponents/MyMobile";

const mapStateToProps = ({ user, dispatch }) => ({
  dispatch,
  user,
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      language: localStorage.getItem("i18nextLng"),
    };
  }

  handlelanguageChange = (e) => {
    localStorage.setItem("language", e.target.value);
    this.props.dispatch({
      type: "settings/SET_STATE",
      payload: {
        language: e.target.value,
      },
    });
    window.location.reload();
  };

  render() {
    window.onscroll = () => {
      headerScroll();
    };

    window.onload = () => {
      headerScroll();
    };

    this.componentDidMount = () => {
      headerScroll();
    };

    function headerScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("th-header").className =
          "fixed-top bg-white shadow-sm th-header-transition";
        document.getElementById("th-header-mobile").className =
          "fixed-top th-hidden-md bg-white shadow-sm th-header-transition";
        document.getElementById("th-header-mobile-menu").className =
          "th-btn-white px-0 py-0";
      } else {
        document.getElementById("th-header").className =
          "fixed-top th-header-transition";
        document.getElementById("th-header-mobile").className =
          "fixed-top th-hidden-md th-header-transition";
        document.getElementById("th-header-mobile-menu").className =
          "th-btn-white-ghost";
      }
    }

    return (
      <>
        {/* Desktop Header */}
        <div id="th-header">
          <MyDesktop>
            <div className="container py-2">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className="my-auto">
                      <Link to="/">
                        <img
                          alt="..."
                          src={logo}
                          style={{
                            mixBlendMode: "luminosity",
                            height: 48,
                            borderRadius: 10,
                          }}
                          className="mr-5"
                        />
                      </Link>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="my-auto pl-2">
                        <Link
                          className="th-bg-color-4 th-white th-14 rounded py-2 px-3"
                          to="/blog"
                        >
                          <Translate name={"Blogs"} />
                        </Link>
                      </div>
                      <div className="my-auto pl-2">
                        <Link
                          className="th-bg-color-4 th-white th-14 rounded py-2 px-3"
                          to="/ExploreArtform"
                        >
                          <Translate name={"Arts/Crafts"} />
                        </Link>
                      </div>
                      {this.props.user.id === "" ? (
                        ""
                      ) : (
                        <div className="my-auto pl-2">
                          <Link
                            className="th-bg-color-4 th-white th-14 rounded py-2 px-3"
                            to="/mycourses"
                          >
                            <Translate name={"My_Courses"} />
                          </Link>
                        </div>
                      )}
                      <div className="my-auto pl-2">
                        <Link
                          className="th-bg-color-4 th-white th-14 rounded py-2 px-3"
                          to="/cart"
                        >
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                      <div className="my-auto pl-2">
                        <select
                          defaultValue={this.state.language}
                          className="th-bg-color-4 th-white th-14 rounded py-2 px-3"
                          style={{ border: "none" }}
                          onChange={this.handlelanguageChange}
                        >
                          <option value="en">English</option>
                          <option value="hin">Hindi</option>
                        </select>
                      </div>
                      {this.props.user.id === "" ? (
                        <Fragment>
                          {" "}
                          <div className="my-auto pl-2 th-black">
                            <Link
                              className="th-btn-white-outline th-14 rounded py-2 px-3 shadow-sm th-black"
                              to="/login"
                            >
                              <Translate name={"Login"} />
                            </Link>
                          </div>
                          <div className="my-auto pl-2 th-black">
                            <Link
                              className="th-btn-color-1 th-14 rounded py-2 px-3 shadow-sm"
                              to="/signup"
                            >
                              <Translate name={"Register"} />
                            </Link>
                          </div>
                        </Fragment>
                      ) : (
                        <div className="my-auto pl-2 th-black">
                          <Link
                            className="th-btn-white-outline th-14 rounded py-2 px-3 shadow-sm th-black"
                            to="/editprofile"
                          >
                            {this.props.user.name.length > 6
                              ? this.props.user.name.substring(0, 6) + "..."
                              : this.props.user.name}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MyDesktop>
        </div>

        {/* Mobile Header */}
        <div id="th-header-mobile">
          <div className="container py-2">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <MyMobile>
                    <div className="my-auto">
                      <Link to="/home">
                        <img
                          alt="..."
                          src={logo}
                          style={{
                            mixBlendMode: "luminosity",
                            height: 32,
                            borderRadius: 4,
                          }}
                          className="mr-5"
                        />
                      </Link>
                    </div>
                  </MyMobile>
                  <div className="my-auto pl-3">
                    <button
                      className="th-btn-white-ghost"
                      id="th-header-mobile-menu"
                      onClick={() =>
                        this.setState({ drawerOpen: !this.state.drawerOpen })
                      }
                    >
                      <MyMobile>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                      </MyMobile>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Drawer */}
        <MyMobile>
          {this.state.drawerOpen ? (
            <div
              className="th-side-drawer-backdrop"
              onClick={() =>
                this.setState({ drawerOpen: !this.state.drawerOpen })
              }
            />
          ) : null}

          <div
            className={
              this.state.drawerOpen
                ? "th-side-drawer open shadow-sm py-5 rounded-left px-3"
                : "th-side-drawer shadow-sm py-5 rounded-left px-3"
            }
          >
            <div className="d-flex justify-content-between">
              <div className="my-3 th-black pr-1">
                <Link
                  className="th-btn-white-outline th-14 rounded py-2 px-3 shadow-sm th-black"
                  to="/login"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Log in
                </Link>
              </div>
              <div className="my-3 th-black">
                <Link
                  className="th-btn-color-1 th-14 rounded py-2 px-3 shadow-sm"
                  to="/signup"
                  onClick={() =>
                    this.setState({ drawerOpen: !this.state.drawerOpen })
                  }
                >
                  Register
                </Link>
              </div>
            </div>
            <hr className="mb-5" />
            <div className="">
              <Link
                className="th-16"
                to="/blog"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Blogs
              </Link>
            </div>
            <div className="my-3">
              <Link
                className="th-16"
                to="/ExploreArtform"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Explore
              </Link>
            </div>
            <div className="my-3">
              <Link
                className="th-16"
                to="/mycourses"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                My Courses
              </Link>
            </div>
            <div className="my-3">
              <Link
                className="th-16 w-100"
                to="/cart"
                onClick={() =>
                  this.setState({ drawerOpen: !this.state.drawerOpen })
                }
              >
                Cart
              </Link>
            </div>
            <hr className="mt-5" />
            <p className="th-grey th-12 text-center">
              www.craftera.dhwajgupta.in
            </p>
          </div>
        </MyMobile>
      </>
    );
  }
}
export default connect(mapStateToProps)(Header);
