import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});

class Translate extends React.Component {
  render() {
    const { t } = this.props;
    return t(`${this.props.name}`);
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation("translations")
)(Translate);
