import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import App from "./App/index";
import ScrollToTop from "./global/ScrollToTop";

const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./App/Screens/Home/index")),
  },
  {
    path: "/category/:slug/:id",
    exact: true,
    Component: lazy(() => import("./App/Screens/Category/index")),
  },
  {
    path: "/profile/all",
    exact: true,
    Component: lazy(() => import("./App/Screens/Profile/index")),
  },
  {
    path: "/profiles/:name/:Id",
    exact: true,
    Component: lazy(() => import("./App/Screens/Profile/other")),
  },
  {
    path: "/blog",
    exact: true,
    Component: lazy(() => import("./App/Screens/Blog/index")),
  },
  {
    path: "/eventDetails/:slug",
    exact: true,
    Component: lazy(() => import("./App/Screens/EventDetails/index")),
  },
  {
    path: "/blogdetails/:slug",
    exact: true,
    Component: lazy(() => import("./App/Screens/BlogDetails/index")),
  },
  {
    path: "/signup",
    exact: true,
    Component: lazy(() => import("./App/Screens/Signup/index")),
  },
  {
    path: "/login",
    exact: true,
    Component: lazy(() => import("./App/Screens/Login/login")),
  },
  {
    path: "/otp",
    exact: true,
    Component: lazy(() => import("./App/Screens/Otp/index")),
  },
  {
    path: "/buyForm",
    exact: true,
    Component: lazy(() => import("./App/Screens/BuyForm/index")),
  },
  {
    path: "/cart",
    exact: true,
    Component: lazy(() => import("./App/Screens/Cart/index")),
  },
  {
    path: "/cart/:slug",
    exact: true,
    Component: lazy(() => import("./App/Screens/Cart/index")),
  },
  {
    path: "/profile2/:id",
    exact: true,
    Component: lazy(() => import("./App/Screens/Profile2/index")),
  },
  {
    path: "/dashboard1",
    exact: true,
    Component: lazy(() => import("./App/Screens/Dashboard1/index")),
  },
  {
    path: "/dashboard2",
    exact: true,
    Component: lazy(() => import("./App/Screens/Dashboard2/index")),
  },
  {
    path: "/dashboard3",
    exact: true,
    Component: lazy(() => import("./App/Screens/Dashboard3/index")),
  },
  {
    path: "/dashboard4",
    exact: true,
    Component: lazy(() => import("./App/Screens/Dashboard4/index")),
  },
  {
    path: "/upload4",
    exact: true,
    Component: lazy(() => import("./App/Screens/Upload4/index")),
  },
  {
    path: "/upload2",
    exact: true,
    Component: lazy(() => import("./App/Screens/Upload2/index")),
  },
  {
    path: "/upload3",
    exact: true,
    Component: lazy(() => import("./App/Screens/upload3/index")),
  },
  {
    path: "/upload1",
    exact: true,
    Component: lazy(() => import("./App/Screens/Upload1/index")),
  },
  {
    path: "/ExploreArtform",
    exact: true,
    Component: lazy(() => import("./App/Screens/ExploreArtform/index")),
  },
  {
    path: "/ExploreCourses",
    exact: true,
    Component: lazy(() => import("./App/Screens/ExploreCourses/index")),
  },
  {
    path: "/PurchasedCourse",
    exact: true,
    Component: lazy(() => import("./App/Screens/PurchasedCourse/index")),
  },
  {
    path: "/contactForm",
    exact: true,
    Component: lazy(() => import("./App/Screens/ContactForm/index")),
  },
  {
    path: "/courseDetails/:slug",
    exact: true,
    Component: lazy(() => import("./App/Screens/CourseDetail/index")),
  },
  {
    path: "/faq",
    exact: true,
    Component: lazy(() => import("./App/Screens/Faq/index")),
  },
  {
    path: "/editprofile",
    exact: true,
    Component: lazy(() => import("./App/Screens/EditProfile/index")),
  },
  {
    path: "/InstructorTerms",
    exact: true,
    Component: lazy(() => import("./App/Screens/InstructorTerms/index")),
  },
  {
    path: "/IntellectualProperty",
    exact: true,
    Component: lazy(() => import("./App/Screens/IntellectualProperty/index")),
  },
  {
    path: "/TermsOfUse",
    exact: true,
    Component: lazy(() => import("./App/Screens/TermsOfUse/index")),
  },
  {
    path: "/AboutUs",
    exact: true,
    Component: lazy(() => import("./App/Screens/About/index")),
  },
  {
    path: "/mycourses",
    exact: true,
    Component: lazy(() => import("./App/Screens/MyCourses/index")),
  },
  {
    path: "/thankyou",
    exact: true,
    Component: lazy(() => import("./App/Screens/ThankYou/index")),
  },
  {
    path: "/failure",
    exact: true,
    Component: lazy(() => import("./App/Screens/Failure/index")),
  },
  {
    path: "/forgotpassword",
    exact: true,
    Component: lazy(() => import("./App/Screens/ForgotPassword/index")),
  },
  {
    path: "/success",
    exact: true,
    Component: lazy(() => import("./App/Screens/Cart/success")),
  },
  {
    path: "/error",
    exact: true,
    Component: lazy(() => import("./App/Screens/Cart/error")),
  },
  {
    path: "/purchasehistory",
    exact: true,
    Component: lazy(() => import("./App/Screens/PurchaseHistory/index")),
  },
];

const Router = ({ history }) => {
  return (
    <ConnectedRouter onUpdate={() => window.scrollTo(0, 0)} history={history}>
      <App>
        <ScrollToTop>
          <Route
            render={(state) => {
              const { location } = state;
              return (
                <Switch location={location}>
                  {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
                  {routes.map(({ path, Component, exact }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => {
                        return (
                          <Suspense fallback={null}>
                            <Component />
                          </Suspense>
                        );
                      }}
                    />
                  ))}
                  <Redirect to="/" />
                </Switch>
              );
            }}
          />
        </ScrollToTop>
      </App>
    </ConnectedRouter>
  );
};

export default Router;
