import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App/Assets/css/General.css";
import ConnectionLostImg from "./App/Assets/img/connection-lost.png";
import React from "react";
import ReactDOM from "react-dom";
import { createHashHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import CacheBuster from "./CacheBuster";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import Router from "./routes";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18next";

axios.defaults.baseURL = "https://crafteraapi.dhwajgupta.in";
axios.defaults.headers.common.Authorization = ``;
axios.defaults.headers.post["Content-Type"] = "application/json";

// middlewared
const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);
ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      // console.log(loading)
      // console.log(isLatestVersion)
      // if (loading) return null
      if (!loading && !isLatestVersion) {
        return (
          <div class="container h-100">
            <div class="row align-items-center h-100">
              <div class="col-md-6 col-xs-12 mx-auto">
                <div class="jumbotron text-center">
                  <h5>Application has received an update</h5>
                  <h5>Please click update button use the application</h5>
                  <button
                    className="th-btn-primary px-3 py-1 my-3"
                    onClick={() => {
                      refreshCacheAndReload();
                    }}
                  >
                    Update Version
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }

      // return (
      //   <Provider store={store}>
      //     <Router history={history} />
      //   </Provider>
      // );

      if (navigator.onLine) {
        return (
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <Router history={history} />
            </I18nextProvider>
          </Provider>
        );
      } else {
        return (
          <div className="container">
            <div className="text-center h-100 pt-5">
              <div
                className="mt-5"
                style={{
                  backgroundImage: `url(${ConnectionLostImg})`,
                  height: 300,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="px-3 py-1 th-14">
                Pull down to refresh{" "}
                <i className="fa fa-angle-double-down" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        );
      }
    }}
  </CacheBuster>,

  document.getElementById("root")
);
serviceWorker.register();
export { store, history };
