import React, { Component } from "react";
import { Link } from "react-router-dom";
import paymentVisa from "../../../Assets/img/payment-visa.png";
import paymentMaster from "../../../Assets/img/payment-master.png";
import paymentPaypal from "../../../Assets/img/payment-paypal.png";
import paymentAmx from "../../../Assets/img/payment-amx.png";
import axios from "axios";
import Translate from "../../../../global/Translate";

export default class Footer extends Component {
  state = {
    message: {
      email: "",
    },
    show: false,
    email: "",
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    this.setState({
      message: {
        ...this.state.message,
        [e.target.name]: e.target.value,
      },
    });
  };
  submit = (e) => {
    e.preventDefault();
    // console.log(this.state.message)
    const { message } = this.state;
    var form_data = new FormData();
    form_data.append("email", message.email);
    form_data.append("status", true);
    axios
      .post(`/clientfrontend/subscribe`, form_data)
      .then((res) => {
        console.log(res);

        this.setState({
          show: true,
          response: res,
        });
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err.response.data);
        this.setState({ email: err.response.data.email });
      });
  };
  render() {
    return (
      <div className="th-footer-background">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-3 col-6 pb-5">
              <p className="th-14 mb-4 th-white">
                <Translate name={"Information"} />
              </p>

              <Link to="/aboutus">
                <p className="th-12 th-white">
                  <Translate name={"About_Us"} />
                </p>
              </Link>
              <Link to="/blog">
                <p className="th-12 th-white">
                  <Translate name={"Blogs"} />
                </p>
              </Link>
              <Link to={"/ExploreCourses"}>
                <p className="th-12 th-white">
                  <Translate name={"courses"} />
                </p>
              </Link>
              <Link to={"/ExploreArtform"}>
                <p className="th-12 th-white">
                  <Translate name={"Art_Forms"} />
                </p>
              </Link>
              <Link to={"/contactForm"}>
                <p className="th-12 th-white">
                  <Translate name={"Contact_us"} />
                </p>
              </Link>
              <Link to={"/InstructorTerms"}>
                <p className="th-12 th-white">
                  <Translate name={"InstructorTerms"} />
                </p>
              </Link>
            </div>
            <div className="col-md-3 col-6 pb-5">
              <p className="th-14 mb-4 th-white">
                <Translate name={"need_help"} />
              </p>

              <Link to={"/signup"}>
                <p className="th-12 th-white">
                  <Translate name={"Register"} />
                </p>
              </Link>
              <Link to={"/cart"}>
                <p className="th-12 th-white">
                  <Translate name={"my_cart"} />
                </p>
              </Link>
              <Link to={"/faq"}>
                <p className="th-12 th-white">
                  <Translate name={"FAQs"} />
                </p>
              </Link>
              <Link to={"/TermsOfUse"}>
                <p className="th-12 th-white">
                  <Translate name={"Terms_and_Conditions"} />
                </p>
              </Link>
              <Link to={"/IntellectualProperty"}>
                <p className="th-12 th-white">
                  <Translate name={"IntellectualProperty"} />
                </p>
              </Link>
              <Link to={"/TermsOfUse"}>
                <p className="th-12 th-white">
                  <Translate name={"TermsOfUse"} />
                </p>
              </Link>
            </div>

            <div className="col-md-6 pb-5">
              <div className="d-flex flex-row">
                <p className="th-14 mb-4 th-white">
                  <Translate name={"CONNECT_WITH_US"} />
                </p>
                <a className="pl-4" href="https://twitter.com/dhwaj_gupta">
                  <i
                    className="fa fa-twitter th-18 th-grey"
                    aria-hidden="true"
                  ></i>
                </a>
                <a className="pl-3" href="https://www.facebook.com/dhwaj.iitr">
                  <i
                    className="fa fa-facebook th-18 th-grey"
                    aria-hidden="true"
                  ></i>
                </a>
                <a className="pl-3" href="https://www.instagram.com/dhwajgupta">
                  <i
                    className="fa fa-instagram th-18 th-grey"
                    aria-hidden="true"
                  ></i>
                </a>
                <a className="pl-3" href="https://dhwajgupta.in">
                  <i
                    className="fa fa-globe th-18 th-grey"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  className="pl-3"
                  href="https://www.youtube.com/channel/UCwhfxyyuw1HAqDLW_c9hoDg"
                >
                  <i
                    className="fa fa-youtube-play th-18 th-grey"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
              <div className="border py-2 mb-5 px-3">
                <form
                  className="d-flex justify-content-between"
                  onSubmit={this.submit}
                >
                  <input
                    type="email"
                    name="email"
                    placeholder="Your E-mail ID"
                    className="th-white th-footer-input th-12"
                    onChange={this.handleInputChange}
                  />
                  <button type="submit" className="th-btn-white-ghost th-12">
                    <Translate name={"subscribe"} />
                  </button>
                </form>
              </div>
              {this.state.show ? (
                // <div class="alert alert-success" role="alert">
                <div className="th-white mt-n3 mb-5">subscribed</div>
              ) : (
                // ""
                <div className="th-white mt-n3 mb-5"> {this.state.email}</div>
              )}
              <div className="d-flex flex-row">
                <p className="th-14 mb-4 th-white">
                  <Translate name={"PAYMENT_OPTIONS"} />
                </p>
                <div
                  className="rounded-top th-footer-image ml-4"
                  style={{
                    backgroundImage: `url(${paymentVisa})`,
                  }}
                />
                <div
                  className="rounded-top th-footer-image ml-1"
                  style={{
                    backgroundImage: `url(${paymentMaster})`,
                  }}
                />
                <div
                  className="rounded-top th-footer-image ml-1"
                  style={{
                    backgroundImage: `url(${paymentPaypal})`,
                  }}
                />
                <div
                  className="rounded-top th-footer-image ml-1"
                  style={{
                    backgroundImage: `url(${paymentAmx})`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
