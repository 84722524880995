import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";

const mapStateToProps = ({ user }) => ({ user });

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: props.children,
    };
  }

  render() {
    const { layout } = this.state;
    return (
      <Fragment>
        <Helmet titleTemplate="%s | Craftera" title="Title" />
        <Header />
        {layout}
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(App));
